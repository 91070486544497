@use '../util/' as u;

.highlights {
  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    gap: u.rem(20);
    width: 100%;
    margin-block: u.rem(20);

    @include u.breakpoint(medium) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: u.rem(20);
    }

    @include u.breakpoint(large) {
      flex-direction: row;
      justify-content: space-around;
      margin-block: u.rem(30);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 90%;
    height: 100%;

    @include u.breakpoint(medium) {
      max-width: 45%;
      height: 100%;
      text-align: center;
    }

    @include u.breakpoint(large) {
      width: 23%;
      min-height: auto;
      text-align: center;
    }
  }

  &__imageContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 100%;
    height: 225px;
    padding: 0;

    & .inner {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 50%;
      width: 100%;
      background-color: var(--highlight-bg);
    }

    img {
      width: 200px;
      aspect-ratio: 1 / 1.1;
      object-fit: cover;
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    }
  }

  &__textContainer {
    background-color: var(--highlight-bg);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding-inline: u.rem(15);
    padding-block: u.rem(20);

    @include u.breakpoint(medium) {
      gap: 0;
    }

    @include u.breakpoint(large) {
      gap: 0;
    }

    h3 {
      color: var(--text-color-light);
      font-weight: 400;
      line-height: 1;

      height: auto;

      @include u.breakpoint(large) {
        min-height: u.rem(45);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    p {
      color: var(--text-color-light);
      font-family: u.$pfont;
      height: auto;
      margin-block-end: u.rem(15);
    }

    button {
      align-self: center;
      height: auto;
      font-family: u.$pfont;
    }
  }
}
