@use '../util/' as u;

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--main-bg);
  font-family: u.$font;
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
  }
}

header ul,
footer ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

img {
  display: block; // Global block style for the img tag, to remove the space below the image
  width: 100%; // globale width 100% for all images. better to set the image-size sperately for each, to avoid wierd behavior. (flex)
  height: auto; // also for the height
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    left: 0;
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid;
  }
}

button {
  a {
    color: var(--text-color-dark);
    text-decoration: none;
  }

  a:visited {
    color: var(--text-color-dark);
  }
}

a {
  color: var(--link-color);
}

a:visited {
  color: var(--link-color);
}

a:hover {
  color: var(--link-color-hover);
}
