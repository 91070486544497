@use '../util/' as u;

.hero {
  width: 100%;
  overflow: hidden;
  &__bg-image {
    width: 100%;
    height: auto;
    background-image: url('../img/hero_bg.jpg');
    background-size: cover;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    padding: u.rem(20);
    background-image: linear-gradient(
        to bottom,
        rgba(105, 105, 105, 0.568),
        transparent
      ),
      url('../img/hero_bg.jpg');

    @include u.breakpoint('medium') {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto auto;
      margin-block-end: u.rem(20);
      // background-image: url('../img/hero_bg.jpg');
    }

    @include u.breakpoint('large') {
      padding: u.rem(50);
      background-image: url('../img/hero_bg.jpg');
    }
  }
  &__title {
    grid-column: 1/3;
    grid-row: 2/3;
    color: var(--text-color-light);
    text-align: center;
    line-height: 1;
    font-weight: 400;
    font-size: 2.5rem;
    font-size: clamp(
      2.5rem,
      2.0598591549295775rem + 1.8779342723004695vw,
      3.75rem
    );
    margin-block-end: u.rem(20);

    @include u.breakpoint('medium') {
      grid-column: 1/2;
      grid-row: 1/2;
      text-align: left;
      margin-block-start: u.rem(30);
      margin-block-end: u.rem(30);
      padding-inline-end: u.rem(20);
    }
  }

  &__left {
    width: 100%;
    grid-column: 1/3;
    grid-row: 3/4;
    height: 100%;

    @include u.breakpoint('medium') {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }

  &__text {
    color: var(--text-color-light);
    font-family: u.$pfont;
    line-height: 1.2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    @include u.breakpoint('medium') {
      text-align: left;
      width: 90%;
    }
  }

  &__content {
    font-size: u.rem(20);
    font-size: clamp(u.rem(20), 1.07rem + 0.75vw, u.rem(28));
    margin-block-end: u.rem(20);

    @include u.breakpoint('medium') {
      margin-block-start: u.rem(0);
      margin-block-end: u.rem(30);
      max-width: 500px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: u.rem(20);
    margin-block-end: u.rem(10);

    @include u.breakpoint('medium') {
      flex-direction: row;
    }
  }

  &__right {
    width: 100%;
    grid-column: 1/3;
    grid-row: 1/2;
    display: grid;
    justify-items: center;
    align-items: center;

    @include u.breakpoint('medium') {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }

  &__image {
    width: u.rem(200);
    width: clamp(u.rem(200), 8.1rem + 18.78vw, u.rem(350));
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    object-position: right;
    margin-top: u.rem(10);
    margin-bottom: u.rem(20);
  }
}
