:root {
  --main-bg: hsl(210, 9%, 96%);

  --navbar-color-bg: #8b9474;
  --dark-color-opacity: rgba(0, 0, 0, 0.65);
  --text-color-light: #ffffff;
  --text-color-dark: hsl(233, 14%, 25%);
  --mobilemenu-bg: #8b9474;
  --dark-bg: #8b9474;

  --content-text-color-light: hsl(0, 0%, 100%);
  --content-text-color-dark: hsl(233, 14%, 25%);
  --content-image-headline-color: hsl(0, 0%, 100%);

  --primary-button-bg: #f5a65b;
  --primary-button-bg-hover: #d89352;
  --cta-button-bg: hsl(210, 9%, 96%);
  --cta-button-bg-hover: hsl(0, 0%, 80%);
  --secondary-button-bg: transparent;
  --secondary-button-bg-hover: hsl(210, 9%, 96%);
  --secondary-button-border: hsl(210, 9%, 96%);
  --secondary-button-border-hover: hsl(210, 9%, 96%);
  --secondary-button-text-color: hsl(210, 9%, 96%);
  --secondary-button-text-color-hover: hsl(233, 14%, 25%);
  --topnav-link-hover: #f5a65b;
  --whatsapp-button-bg: #25d366;
  --whatsapp-button-bg-hover: #1eaa52;

  --singlepage-link-color: #b15500;
  --singlepage-link-color-hover: #d89352;

  --link-color: #f5a65b;
  --link-color-hover: #f5a65b;

  --highlight-bg: #6cae75;
  --quote-bg: #c1cc99;
  --card-bg: #f5a65b;
  --testimonial-bg: #8bbd8b;
  --socialcontainer-bg: #5e644e;
  --cta-bg: #f5a65b;
  --sp-bg: #c1cc9994;
  --dividerheadline-bg: #5e644e;
  --dividersubheadline-bg: #8b9474;

  --fc-button-bg-color: hsl(210, 9%, 96%);
  --fc-button-text-color: hsl(233, 14%, 25%);
  --fc-button-bg-color-hover: hsl(0, 0%, 80%);
  --fc-border-color: none;

  --dark-color-mobile-gallery-opacity: rgba(0, 0, 0, 0.4);

  --all-color: hsl(0, 0%, 50%);
  --react-color: hsl(207, 46%, 63%);
  --mongo-db-color: hsl(120, 56%, 22%);
  --express-color: hsl(0, 0%, 0%);
  --node-color: hsl(123, 100%, 32%);
  --sass-color: hsl(310, 57%, 59%);
  --javascript-color: hsl(50, 100%, 33%);
  --css-color: hsl(228, 78%, 52%);
  --website-color: hsl(14, 100%, 63%);
  --web-app-color: hsl(291, 19%, 52%);
  --api-color: hsl(0, 47%, 40%);
}
