@use '../util/' as u;

.quote {
  &__section {
    background-color: var(--quote-bg);
    padding: u.rem(20);

    @include u.breakpoint(medium) {
      padding-block: u.rem(60);
    }

    @include u.breakpoint(large) {
      padding-block: u.rem(80);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: u.rem(20);
    text-align: center;

    h2 {
      color: var(--text-color-dark);
      font-size: u.rem(40);
      font-size: clamp(u.rem(40), 2.32rem + 0.75vw, u.rem(48));
      line-height: 1;
      max-width: 600px;
    }

    p {
      color: var(--text-color-dark);
      font-size: u.rem(20);
      font-size: clamp(u.rem(20), 1.16rem + 0.38vw, u.rem(24));
      line-height: 1;
      max-width: 600px;
    }
  }

  &__picturecontainer {
    background-image: url(../img/quoteimage.jpg);
    background-size: cover;
    background-position: center;
    height: u.rem(300);
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    img {
      width: 90%;
      height: auto;
      object-fit: cover;
    }

    @include u.breakpoint(medium) {
      width: 100%;
      max-width: u.rem(700);
      max-height: u.rem(400);
      padding-block: u.rem(20);

      img {
        margin-block: u.rem(20);
        width: 15rem;
      }
    }
  }
}
