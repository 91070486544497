@use '../util/' as u;

.singlepage {
  &__contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--sp-bg);
    padding: u.rem(20);

    margin-bottom: u.rem(20);

    @include u.breakpoint('medium') {
      padding-inline: u.rem(30);
    }

    @include u.breakpoint('large') {
      padding: u.rem(50);
    }

    p {
      font-family: u.$pfont;
      color: var(--content-text-color-dark);
      text-align: justify;
    }
  }

  &__dividerHeadline {
    width: 100%;
    text-align: center;
    padding-block: u.rem(10);
    background-color: var(--dividerheadline-bg);
    color: var(--content-text-color-light);
    margin-block-end: u.rem(20);
    text-transform: uppercase;

    h2 {
      font-weight: 400;
    }
  }

  &__dividerSubHeadline {
    width: 100%;
    text-align: center;
    padding-block: u.rem(2);
    background-color: var(--dividersubheadline-bg);
    color: var(--content-text-color-light);
    margin-block-start: u.rem(30);
    margin-block-end: u.rem(20);

    h2 {
      font-weight: 400;
    }

    @include u.breakpoint('medium') {
      margin-block-start: u.rem(30);
    }
  }

  &__pictureContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @include u.breakpoint('medium') {
      min-width: 300px;
      max-width: 300px;
    }

    @include u.breakpoint('large') {
      min-width: 500px;
      max-width: 500px;
    }
  }

  &__textandpictureContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: u.rem(20);
    max-width: 1000px;

    &.wij {
      align-items: center;

      h3 {
        text-align: left;
      }
    }

    &.reverse {
      @include u.breakpoint('medium') {
        flex-direction: row-reverse;
      }
    }

    @include u.breakpoint('medium') {
      flex-direction: row;
      gap: u.rem(30);
    }

    img {
      @include u.breakpoint('medium') {
        max-height: u.rem(300);
        object-fit: cover;
      }
    }
  }

  &__picture {
    &__avatar {
      border-radius: 50%;
      width: 12.5rem;
      width: clamp(
        12.5rem,
        10.855263157894736rem + 8.771929824561402vw,
        18.75rem
      );

      @include u.breakpoint('medium') {
        min-width: 300px;
        max-width: 300px;
      }
    }
  }

  &__content {
    padding-inline: u.rem(20);
    padding-block: u.rem(20);

    @include u.breakpoint('medium') {
      padding-inline: u.rem(50);
      padding-block: u.rem(50);
    }

    h2 {
      color: var(--content-text-color-dark);
    }

    &__formular {
      margin-block-start: u.rem(30);
      width: 100%;

      @include u.breakpoint('large') {
        width: 80%;
      }
    }
  }

  &__ctaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: u.rem(20);
    text-align: center;

    button {
      width: 80%;
      max-width: 300px;
      align-self: center;
    }
  }

  &__contactContainer {
    width: 100%;
    background-color: var(--dark-bg);
    display: grid;
    padding-block: u.rem(20);
    padding-inline: u.rem(20);
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @include u.breakpoint('large') {
      grid-template-columns: 2fr auto;
      grid-template-rows: auto;
      padding-inline: u.rem(30);
    }

    &__wabuttonContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-block-start: u.rem(20);

      .button {
        color: var(--text-color-light);
        width: 100%;
        max-width: 300px;
        display: flex;
        justify-content: center;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: u.rem(5);
      }
    }

    &__text {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      color: var(--text-color-light);
      margin-block-start: u.rem(20);

      table {
        td {
          padding: 5px;
          padding-inline-end: 10px;
          text-align: left;

          &:first-child {
            font-weight: 400;
          }
        }

        @include u.breakpoint('medium') {
          margin-left: 30px;
        }
      }

      a,
      a:visited {
        color: var(--text-color-light);
      }

      a:hover {
        color: var(--text-color-light);
      }
    }
  }
}

a,
a:visited {
  color: var(--singlepage-link-color);
}

a:hover {
  color: var(--singlepage-link-color-hover);
}
